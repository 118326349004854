<template>
  <b-modal
    id="modal-lg"
    ok-title="Add to pathwayData"
    centered
    size="lg"
    title="Search for Course"
    ref="modal"
    @hidden="() => $emit('update:add-course-active', false)"
    :visible="addCourseActive"
    :hide-footer="true"
    body-class="p-0"
  >
    <div no-body class="border-0">
      <b-row class="pt-25 pl-1 pr-1 pb-1">
        <b-col cols="12" md="6">
          <b-form-group class="justify-content-start mb-0">
            <div class="d-flex align-items-center">
              <b-form-input v-model="searchTerm" placeholder="Search Course" type="text" class="d-inline-block" @input="handleSearch" />
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="pathwayAddCourseTable"
        :busy.sync="isBusy"
        :items="courseProvider"
        :fields="courseFields"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchTerm"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="border-bottom"
      >
        <template #cell(name)="data">
          <div class="d-flex align-items-center" style="gap: 0.85rem">
            <b-avatar
              size="30"
              :src="data.item.avatar"
              :text="avatarText(data.item.name)"
              variant="light-info"
              :to="{ name: 'apps-roster-course-view', params: { id: data.item.id } }"
              rounded
            />
            <div class="font-weight-boldtext-white mb-0" style="font-size: 1.25rem; line-height: 1.75rem">
              {{ data.item.name }}
            </div>
          </div>
        </template>

        <!-- Column: Visibility -->
        <template #cell(visibility)="data">
          <b-badge :variant="`light-${resolveCourseVisibilityVariant(data.item.visibility)}`" class="text-capitalize">
            {{ data.item.visibility.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolveCourseStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status.split('_').join(' ') }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-button @click="assignCourse(data.item)" variant="success" size="sm">Add</b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="mb-0 text-muted">{{ totalResults }} Total Entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalResults"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-sm-0 ml-lg-50"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BAvatar,
  BTable,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BCard,
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import Prism from 'vue-prism-component';
import store from '@/store';
import { computed, onUnmounted, ref } from '@vue/composition-api';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import { avatarText } from '@core/utils/filter';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';
import moment from 'moment';
import { title } from '@core/utils/filter';
import useCourses from '../../courses/useCourses';

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCol,
    BRow,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
  },
  data() {
    return {
      isBusy: false,
      searchTerm: '',
      perPage: 10,
      currentPage: 1,
      totalResults: 0,
      totalPageCount: 0,

      courseFields: [
        { key: 'name', sortable: true },
        { key: 'visibility', sortable: true },
        { key: 'status', sortable: true },
        { key: 'actions' },
      ],

      sortBy: 'createdAt',
      sortDesc: true,

      course: undefined,
    };
  },
  props: {
    addCourseActive: {
      type: Boolean,
    },
    pathwayData: {
      type: Object,
    },
  },
  methods: {
    courseProvider(ctx) {
      const promise = store.dispatch('rosterStoreModule/fetchCourseList', {
        search: ctx.filter,
        limit: ctx.perPage,
        page: ctx.currentPage,
        sortBy: `${ctx.sortBy || 'createdAt'}:${ctx.sortDesc ? 'desc' : 'asc'}`,
      });

      return promise
        .then((response) => {
          const { results, totalResults, totalPages } = response.data;
          this.rows = results;
          this.totalResults = totalResults;
          this.totalPageCount = totalPages;
          return results;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching course list-pending',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          return [];
        });
    },
    assignCourse(course) {
      const courseExists = this.pathwayData.courses.find((item) => item.id === course.id);
      if (courseExists) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'XIcon',
            variant: 'danger',
            title: 'Failed to add to course',
            text: 'This course is already assigned to this pathway',
          },
        });
      }

      store
        .dispatch('rosterStoreModule/createCourseToPathway', {
          pathwayId: this.pathwayData.id,
          courseIds: [course.id],
        })
        .then((response) => {
          const { data: pathwayData } = response;

          this.$emit('update:add-course-active', false);
          this.$emit('refetch-data');

          return this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'EditIcon',
              variant: 'success',

              title: 'Added course to pathway',
              text: 'This course has been assigned to this pathway',
            },
          });
        })
        .catch((err) => {
          console.error(`failed to add course to pathway`, err);
        });
    },
    handleSearch(searching) {
      this.searchTerm = searching;
    },
  },

  setup() {
    const toast = useToast();

    const { perPageOptions, resolveCourseRoleVariant, resolveCourseRoleIcon, resolveCourseVisibilityVariant, resolveCourseStatusVariant } =
      useCourses();

    return {
      toast,
      title,
      moment,
      avatarText,
      perPageOptions,
      resolveCourseRoleVariant,
      resolveCourseRoleIcon,
      resolveCourseVisibilityVariant,
      resolveCourseStatusVariant,
    };
  },
};
</script>
