<template>
  <div class="container content-p">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="pathwayData === undefined">
      <h4 class="alert-heading">Error fetching pathway data</h4>
      <div class="alert-body">
        No pathway found with this pathway id. Check
        <b-link class="alert-link" :to="{ name: 'apps-roster-pathway-list-pending' }"> Pathway List </b-link>
        for other pathways.
      </div>
    </b-alert>

    <div v-if="pathwayData" :key="pathwayData.id">
      <pathway-edit-modal :edit-pathway-active.sync="editPathwayActive" :pathway-data="pathwayData" @refetch-data="fetchData" />
      <pathway-add-course-modal :add-course-active.sync="addCourseActive" :pathway-data="pathwayData" @refetch-data="fetchData" />
      <pathway-cohort-create-modal
        :create-pathway-cohort-active.sync="createPathwayCohortActive"
        :pathway-data="pathwayData"
        @refetch-data="fetchData"
      />

      <b-row class="mb-25">
        <b-col cols="12" md="12">
          <pathway-view-header :pathway-data="pathwayData" :edit-pathway-active.sync="editPathwayActive" @refetch-data="fetchData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="9" md="12" class="mb-2">
          <pathway-view-cohort-list
            v-if="$can('roster.cohort.list')"
            :pathway-data="pathwayData"
            :create-pathway-cohort-active.sync="createPathwayCohortActive"
          />
          <h3 v-else>You are not authorized to view cohorts</h3>
        </b-col>
        <b-col cols="12" lg="3" md="12" class="mb-2">
          <pathway-view-courses-card :pathway-data="pathwayData" :add-course-active.sync="addCourseActive" @refetch-data="fetchData" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import { BRow, BCol, BAlert, BLink, BButton, BCard } from 'bootstrap-vue';
import vSelect from 'vue-select';
import rosterStoreModule from '../../../../../store/roster/rosterStoreModule';
import PathwayViewHeader from './PathwayViewHeader.vue';
import PathwayViewCoursesCard from './PathwayViewCoursesCard.vue';
import PathwayViewCohortList from './PathwayViewCohortList.vue';
import PathwayEditModal from '../edit/PathwayEditModal.vue';
import PathwayAddCourseModal from '../edit/PathwayAddCourseModal.vue';
import { avatarText } from '@core/utils/filter';
import PathwayCohortCreateModal from '../cohorts/create/PathwayCohortCreateModal.vue';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BButton,

    vSelect,

    // Local Components
    PathwayViewHeader,
    PathwayViewCohortList,
    PathwayEditModal,
    PathwayAddCourseModal,
    PathwayViewCoursesCard,
    PathwayCohortCreateModal,

    avatarText,
  },
  metaInfo() {
    const title = this.pathwayData ? `${this.pathwayData.name}` : 'View Pathway';
    return {
      title,
    };
  },
  watch: {
    '$route.path'() {
      this.fetchData();
    },
  },
  setup() {
    const pathwayData = ref(null);
    const createPathwayCohortActive = ref(false);
    const editPathwayActive = ref(false);
    const addCourseActive = ref(false);

    const fetchData = () => {
      store
        .dispatch('rosterStoreModule/fetchPathway', {
          id: router.currentRoute.params.id,
          queryParams: { populate: 'courses' },
        })
        .then((response) => {
          pathwayData.value = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            pathwayData.value = undefined;
          }
        });
    };

    fetchData();

    return {
      pathwayData,
      fetchData,
      createPathwayCohortActive,
      editPathwayActive,
      addCourseActive,
    };
  },
};
</script>

<style></style>
