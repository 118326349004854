<template>
  <div class="pl-50">
    <course-edit-modal :edit-course-active.sync="editCourseActive" :course-data="editCourseData" @refetch-data="$emit('refetch-data')" />

    <div class="d-flex align-items-center justify-content-between mb-75">
      <h3 class="ml-25 mb-0">Courses</h3>
      <div
        v-if="$can('roster.pathway.update')"
        @click="$emit('update:add-course-active', true)"
        class="d-flex align-items-center text-muted cursor-pointer"
        style="margin-top: 2px"
      >
        <span class="mr-50">Add Course</span>
        <feather-icon icon="PlusCircleIcon" size="18" />
      </div>
    </div>

    <draggable class="list-group list-group-flush cursor-move" tag="ul">
      <div
        v-for="course in pathwayData.courses"
        :key="course.id"
        :style="`${calculateBorderColor(calculateCourseColor(course))} padding: 0.075rem`"
        class="mb-1 rounded position-relative overflow-hidden drop-shadow-default"
        @contextmenu.prevent="$refs.pathwayViewCourseEditMenu.open($event, { course })"
      >
        <div class="rounded" style="background: #191a22">
          <div class="card-body rounded z-2 position-relative" :style="calculateBackgroundColor(calculateCourseColor(course))">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <b-avatar
                  :src="course.avatar"
                  :text="avatarText(course.name)"
                  size="55px"
                  variant="light-info"
                  class="mr-1 d-none d-lg-block"
                  rounded
                />
                <div class="d-flex flex-column align-items-start justify-content-center">
                  <div class="d-flex align-items-center mb-50">
                    <h4 class="font-weight-bolder tracking-tighter mr-50 mb-0">{{ course.name }}</h4>
                  </div>

                  <div class="d-flex align-items-center">
                    <b-badge class="profile-badge mr-25" :variant="`${resolvePathwayStatusVariant(course.status)}`">
                      <feather-icon :icon="resolvePathwayStatusIcon(course.status)" size="12" />
                      {{ title(course.status) }}
                    </b-badge>
                    <b-badge class="profile-badge" :variant="`light-${resolvePathwayVisibilityVariant(course.visibility)}`">
                      <feather-icon :icon="resolvePathwayVisibilityIcon(course.visibility)" size="12" />
                      {{ title(course.visibility) }}
                    </b-badge>
                  </div>
                </div>
              </div>
              <feather-icon
                @click="$router.push({ name: 'apps-roster-course-view', params: { id: course.id } })"
                icon="EyeIcon"
                size="16"
                class="cursor-pointer text-muted"
                style="margin-top: 1px; min-width: 24px"
              />
            </div>
          </div>
        </div>
      </div>
    </draggable>

    <vue-context ref="pathwayViewCourseEditMenu" class="mw-8" v-slot="{ data }">
      <li v-if="data">
        <a
          v-if="$can('roster.course.get')"
          class="d-flex align-items-center justify-content-between"
          @click="$router.push({ name: 'apps-roster-course-view', params: { id: data.course.id } })"
        >
          <span>View</span>
          <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
        </a>
        <a v-if="$can('roster.course.update')" class="d-flex align-items-center justify-content-between" @click="editCourse(data.course)">
          <span>Edit</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </a>
        <a
          v-if="$can('roster.pathway.update')"
          class="d-flex align-items-center justify-content-between"
          @click="removeCourse(data.course)"
        >
          <span>Remove</span>
          <feather-icon icon="DeleteIcon" size="15" class="ml-50 text-muted" />
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BBadge } from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import usePathways from '../usePathways';
import { title } from '@core/utils/filter';
import useAppConfig from '@core/app-config/useAppConfig';
import VueContext from 'vue-context';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import store from '@/store';
import { onUnmounted, ref } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import draggable from 'vuedraggable';
import { hexToRGB } from '@/libs/utils/color';
import CourseEditModal from '../../courses/edit/CourseEditModal.vue';
const { skin } = useAppConfig();

export default {
  data() {
    return {
      course: undefined,
    };
  },
  components: {
    draggable,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    VueContext,
    CourseEditModal,
  },
  props: {
    pathwayData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    calculateCourseColor(courseData) {
      if (courseData.metadata && courseData.metadata.design && courseData.metadata.design.color) {
        return courseData.metadata.design.color;
      }
      return '#5b5b5e';
    },
    calculateBackgroundColor(hex) {
      const rgb = hexToRGB(hex);
      return `background: linear-gradient(200deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.25) 0%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.05) 93.34%)`;
    },
    calculateBorderColor(hex) {
      const rgb = hexToRGB(hex);
      return `background: linear-gradient(200deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.7) 0%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.03) 100%);`;
    },
    editCourse(data) {
      this.editCourseData = data;
      this.editCourseActive = true;
    },
    removeCourse(course) {
      const courseExists = this.pathwayData.courses.find((item) => item.id === course.id);
      if (!courseExists) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'XIcon',
            variant: 'danger',
            title: 'Failed to remove course',
            text: 'This course is not assigned to this pathway',
          },
        });
      }

      store
        .dispatch('rosterStoreModule/removeCourseFromPathway', {
          pathwayId: this.pathwayData.id,
          courseIds: [course.id],
        })
        .then((response) => {
          const { data: pathwayData } = response;

          this.$emit('refetch-data');

          return this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'EditIcon',
              variant: 'success',
              title: 'Removed course from pathway',
              text: 'This course has been removed from this pathway',
            },
          });
        })
        .catch((err) => {
          console;
          console.error(`failed to add course to pathway`, err);
        });
    },
  },
  setup() {
    const createCourseActive = ref(false);
    const editCourseData = ref({});
    const editCourseActive = ref(false);
    const course = ref(null);

    const { resolvePathwayStatusVariant, resolvePathwayStatusIcon, resolvePathwayVisibilityVariant, resolvePathwayVisibilityIcon } =
      usePathways();
    return {
      createCourseActive,
      editCourseData,
      editCourseActive,
      course,
      avatarText,
      resolvePathwayStatusVariant,
      resolvePathwayStatusIcon,
      resolvePathwayVisibilityIcon,
      resolvePathwayVisibilityVariant,
      title,
      skin,
    };
  },
};
</script>
