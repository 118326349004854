<template>
  <div class="pr-50">
    <pathway-cohort-edit-modal
      :edit-pathway-cohort-active.sync="editPathwayCohortActive"
      :pathway-cohort-data="editPathwayData"
      @refetch-data="refetchData"
    />

    <div class="d-flex align-items-center mb-75">
      <h3 class="mb-0 mr-50">Cohorts</h3>
      <div
        @click="$emit('update:create-pathway-cohort-active', true)"
        class="d-flex align-items-center text-muted cursor-pointer"
        style="margin-top: 3px; margin-left: 3px"
      >
        <feather-icon icon="PlusCircleIcon" size="18" class="mr-25" />
        <span>New Cohort</span>
      </div>
    </div>

    <div class="mb-75">
      <b-row>
        <b-col cols="12" sm="8" md="7">
          <div class="d-flex align-items-center justify-content-start flex-wrap flex-sm-nowrap" style="gap: 0.675rem">
            <b-form-input v-model="searchQuery" class="drop-shadow-default" placeholder="Search..." style="max-width: 20rem" />
            <session-list-filters
              :visibility-filter.sync="visibilityFilter"
              :status-filter.sync="statusFilter"
              :visibility-options="visibilityOptions"
              :status-options="statusOptions"
            />
          </div>
        </b-col>

        <b-col cols="12" sm="4" md="5" class="d-flex align-items-center justify-content-end"></b-col>
      </b-row>
    </div>

    <b-card no-body class="mb-0 table-no-top">
      <b-table
        ref="refPathwayCohortListTable"
        class="position-relative"
        :items="fetchPathwayCohorts"
        :fields="tableColumnsPathwayView"
        responsive
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :sort-by-formatted="true"
        show-empty
        empty-text="No matching records found"
        @row-contextmenu="onRowContextMenu"
      >
        <!-- Column: Session -->
        <template #cell(startDate)="data">
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column">
              <b-link
                :to="{ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.id } }"
                class="font-weight-bold text-nowrap text-white mb-0"
                style="font-size: 1.25rem; line-height: 1.75rem"
              >
                {{ resolvePathwayCohortName(data.item) }}
              </b-link>
              <b-link
                :to="{ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.id } }"
                :class="resolvePathwayCohortDateMessageVariant(data.item.startDate, data.item.endDate)"
              >
                <small style="font-size: 1.05rem; line-height: 1.35rem" class="d-flex align-items-center">
                  <feather-icon
                    :icon="resolvePathwayCohortDateMessageIcon(data.item.startDate, data.item.endDate)"
                    size="14"
                    class="mr-25"
                    style="margin-top: 1px"
                  />
                  {{ resolvePathwayCohortDateMessage(data.item.startDate, data.item.endDate) }}</small
                >
              </b-link>
            </div>
          </div>
        </template>

        <!-- Column: Sections -->
        <template #cell(sections)="data">
          <div class="d-flex align-items-center" style="gap: 0.625rem">
            <div v-for="section in data.value" v-if="section">
              <div v-if="section.course" class="d-flex flex-column">
                <b-avatar
                  size="32"
                  :src="section.course.avatar"
                  :text="avatarText(section.course.name)"
                  :variant="`light-info`"
                  :to="{ name: 'apps-roster-course-section-view', params: { id: data.item.id } }"
                  class="align-self-center"
                  rounded
                />
                <span class="text-muted text-center" style="font-size: 0.9rem">{{
                  resolveCourseSectionDateFormat(section.startDate, 'MMM Do')
                }}</span>
              </div>
            </div>
          </div>
        </template>

        <!-- Column: Total Students -->
        <template #cell(profiles)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize" :class="data.value ? 'text-white' : 'text-muted'">{{
              data.value ? `${data.value} Student${data.value !== 1 ? 's' : ''}` : 'No Students Enrolled'
            }}</span>
          </div>
        </template>

        <!-- Column: Visibility -->
        <template #cell(visibility)="data">
          <b-badge :variant="`light-${resolvePathwayCohortVisibilityVariant(data.item.visibility)}`" class="text-capitalize">
            {{ data.item.visibility.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolvePathwayCohortStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="primary" class="dropdown-edit-button">
            <template #button-content>
              <div class="p-50">
                <feather-icon icon="MoreHorizontalIcon" size="21" />
              </div>
            </template>

            <b-dropdown-item v-if="$can('roster.cohort.update')" @click="editPathwayCohort(data.item)">
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>Edit</span>
                <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('roster.cohort.get')"
              @click="$router.push({ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.id } })"
            >
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>View</span>
                <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="mb-0 text-muted">{{ dataMeta.of }} Total Entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
            <div class="mr-2 vue-select-up">
              <span class="mb-0 mr-75">Rows Per Page</span>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block"
                :open="true"
              />
            </div>
            <span class="mr-2 ml-lg-50 mb-0">Page {{ currentPage }} of {{ totalPathwayCohorts }}</span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPathwayCohorts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-sm-0 ml-lg-50"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <vue-context ref="pathwayViewCohortListMenu" class="mw-8" v-slot="{ data }">
      <li v-if="data">
        <a
          v-if="$can('roster.cohort.update')"
          class="d-flex align-items-center justify-content-between"
          @click="editPathwayCohort(data.item)"
        >
          <span>Edit</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </a>
        <a
          v-if="$can('roster.cohort.get')"
          class="d-flex align-items-center justify-content-between"
          @click="$router.push({ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.id } })"
        >
          <span>View</span>
          <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTable,
  BFormInput,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownDivider,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { avatarText } from '@core/utils/filter';
import useAppConfig from '@core/app-config/useAppConfig';
import usePathwayCohorts from '../cohorts/usePathwayCohorts';
import SessionListFilters from '../cohorts/list/PathwayCohortListFilters.vue';
import VueContext from 'vue-context';
import PathwayCohortEditModal from '@/views/apps/roster/pathways/cohorts/edit/PathwayCohortEditModal.vue';
import { ref, onUnmounted } from '@vue/composition-api';
import useCourseSections from '../../courses/sections/useCourseSections';

export default {
  components: {
    SessionListFilters,

    BRow,
    BCol,
    BAlert,
    BLink,
    BTable,

    BCard,
    BFormInput,
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownDivider,

    vSelect,
    VueContext,

    PathwayCohortEditModal,
    BTooltip,
  },
  watch: {
    '$props.pathwayData'() {
      this.refetchData();
    },
  },
  methods: {
    archiveSession(sessionId) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to archive this session?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          store
            .dispatch('rosterStoreModule/updateSession', {
              sessionId,
              sessionData: { status: 'archived' },
            })
            .then((response) => {
              this.fetchData();
            });
        }
      });
    },
    editPathwayCohort(data) {
      this.editPathwayData = data;
      this.editPathwayCohortActive = true;
    },
    onRowContextMenu(item, index, event) {
      this.$refs.pathwayViewCohortListMenu.open(event, { item });
      event.preventDefault();
    },
  },
  props: {
    pathwayData: {
      type: Object,
      required: true,
    },
    createPathwayCohortActive: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup(props) {
    const editPathwayData = ref({});
    const editPathwayCohortActive = ref(false);

    const { pathwayData } = props;

    const {
      fetchPathwayCohorts,
      tableColumnsPathwayView,
      perPage,
      currentPage,
      totalPathwayCohorts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPathwayCohortListTable,
      refetchData,
      isLoaded,

      statusOptions,
      visibilityOptions,

      // UI
      resolvePathwayCohortName,
      resolvePathwayCohortDateMessage,
      resolvePathwayCohortDateMessageVariant,
      resolvePathwayCohortVisibilityVariant,
      resolvePathwayCohortStatusVariant,
      resolvePathwayCohortDateMessageIcon,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    } = usePathwayCohorts(pathwayData);

    const { resolveCourseSectionDateFormat } = useCourseSections();

    return {
      avatarText,
      fetchPathwayCohorts,
      tableColumnsPathwayView,
      perPage,
      currentPage,
      totalPathwayCohorts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPathwayCohortListTable,
      refetchData,
      isLoaded,

      statusOptions,
      visibilityOptions,

      // UI
      resolvePathwayCohortDateMessage,
      resolvePathwayCohortDateMessageVariant,
      resolveCourseSectionDateFormat,
      resolvePathwayCohortName,
      resolvePathwayCohortVisibilityVariant,
      resolvePathwayCohortStatusVariant,
      resolvePathwayCohortDateMessageIcon,

      // Extra Filters
      visibilityFilter,
      statusFilter,

      editPathwayData,
      editPathwayCohortActive,
    };
  },
};
</script>

<style></style>
