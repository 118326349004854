<template>
  <b-modal
    ref="modalUpsertTemplate"
    :visible="createPathwayCohortActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    body-class="pt-0"
    :title="`New Pathway Cohort`"
    centered
  >
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <label class="d-block">Pathway</label>
        <div class="d-flex align-items-center mb-1 border rounded p-50">
          <b-avatar :src="pathwayData.avatar" :text="avatarText(pathwayData.name)" size="45px" variant="light-info" class="mr-75" rounded />
          <h2 class="mb-0 font-weight-bolder">{{ pathwayData.name }}</h2>
        </div>
      </b-col>

      <b-col cols="12" v-for="course in pathwayCohortSections" :key="course.id">
        <b-form-group :label="`${course.name} Section`" :label-for="`pathway-cohort-${course.id}`">
          <div class="d-flex align-items-center">
            <b-avatar :src="course.avatar" :text="avatarText(course.name)" size="35px" variant="light-info" class="mr-50" rounded />
            <vue-autosuggest
              id="course-section-instructor"
              ref="autocomplete"
              :suggestions="course.suggestedSections"
              :input-props="courseSectionInputProps"
              :render-suggestion="courseSectionRenderSuggestion"
              class="w-100"
              @selected="onCourseSectionSelected($event, course)"
              @input="courseSectionFetchResults($event, course)"
            />
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Visibility" label-for="pathway-cohort-visibility">
          <v-select
            v-model="pathwayCohortCreateData.visibility"
            :options="visibilityOptions"
            :clearable="false"
            :reduce="(val) => val.value"
            required
            transition="smooth"
            input-id="pathway-cohort-visibility"
          />
        </b-form-group>
      </b-col>

      <!-- <b-col cols="12" md="12" lg="12">
        <b-form-group label="Section Alias" label-for="pathway-cohort-nickname">
          <b-form-input id="pathway-cohort-nickname" placeholder="" v-model="pathwayCohortCreateData.nickname" />
        </b-form-group>
      </b-col> -->
      <!--      
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Status" label-for="pathway-cohort-status">
          <v-select
            v-model="pathwayCohortCreateData.status"
            :options="statusOptions"
            :clearable="false"
            :reduce="(val) => val.value"
            required
            transition="smooth"
            input-id="pathway-cohort-status"
          />
        </b-form-group>
      </b-col> -->
    </b-row>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button variant="secondary" class="ml-75" @click="closeModal">Close</b-button>
        <b-button variant="primary" class="ml-75" @click="createEntity">Create Cohort</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BModal,
  BRow,
  VBModal,
  BAvatar,
} from 'bootstrap-vue';

import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import store from '@/store';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import userStoreModule from '@/store/user/userStoreModule';
import { ref, onUnmounted } from '@vue/composition-api/dist/vue-composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import usePathwayCohorts from '../usePathwayCohorts';
import vSelect from 'vue-select';
import { avatarText } from '@core/utils/filter';
import { VueAutosuggest } from 'vue-autosuggest';
import useCourseSections from '../../../courses/sections/useCourseSections';

export default {
  name: 'PathwayCohortCreateModal',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BModal,
    BAvatar,
    quillEditor,
    vSelect,
    VueAutosuggest,
  },
  directives: { 'b-modal': VBModal },
  props: {
    createPathwayCohortActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    pathwayData: {
      type: Object,
      default: undefined,
      default: false,
    },
  },
  watch: {
    '$props.createPathwayCohortActive'(val) {
      this.initModal();
    },
  },
  mounted() {
    this.initModal();
  },
  methods: {
    courseSectionRenderSuggestion(suggestion) {
      const section = suggestion.item;
      return (
        <div>
          {section.course ? (
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center" style="gap: 0.625rem">
                <div class="">
                  <h4 class="font-weight-normal " style="margin-bottom: 3px;">
                    {this.resolveCourseSectionName(section)}
                  </h4>
                  <div class="d-flex align-items-center text-muted">
                    {section.instructor ? (
                      <b-avatar
                        src={section.instructor.avatar}
                        text={avatarText(section.instructor.name)}
                        variant="light-info"
                        size="20"
                        style="margin-right: 5px"
                      />
                    ) : null}
                    <small style="font-size: 1.20rem; line-height: 1.45rem">
                      {section.instructor ? section.instructor.name : 'No Instructor Assigned'}
                    </small>
                  </div>
                </div>
              </div>
              {section.profiles.length === 0 ? (
                <small class="text-muted" style="font-size: 1.05rem; line-height: 1.35rem">
                  No Students Enrolled
                </small>
              ) : (
                <div class="d-flex align-items-center">
                  <feather-icon icon="UsersIcon" size="15" style="margin-right: 5px" />
                  <small style="font-size: 1.05rem; line-height: 1.35rem">{section.profiles.length} Students</small>
                </div>
              )}
            </div>
          ) : null}
        </div>
      );
    },
    initModal() {
      this.pathwayCohortCreateData = { visibility: 'public', status: 'draft', sections: [] };
    },
    closeModal() {
      this.busy = false;
      this.$emit('update:create-pathway-cohort-active', false);
    },
    createEntity(event) {
      this.busy = true;
      event.preventDefault();

      const allSectionsSelected = this.pathwayCohortSections.every((course) => course.selectedSection);
      if (!allSectionsSelected) {
        console.log('At least one course is missing a selected section.');
        this.busy = false;
        return;
      }

      const sections = this.pathwayCohortSections.map((course) => course.selectedSection.id);
      const pl = { ...this.pathwayCohortCreateData, sections };

      if (this.instructorSelected) {
        pl.instructor = this.instructorSelected.id;
      }

      store
        .dispatch('rosterStoreModule/createPathwayCohort', { cohortId: this.pathwayData.id, pathwayCohortData: pl })
        .then((response) => {
          // this.$emit('update:pathway-data', response.data);

          this.busy = false;
          this.$emit('refetch-data');
          this.closeModal();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Pathway Cohort Created!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });

          return this.$router.push(`/roster/pathways/cohorts/${response.data.id}`);
        })
        .catch((error) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit pathway cohort',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  setup(props) {
    const pathwayCohortCreateData = ref({});
    const pathwayCohortSections = ref({});
    const busy = ref(false);

    const { resolveCourseSectionName } = useCourseSections();
    const { statusOptions, visibilityOptions } = usePathwayCohorts();

    const courseSectionInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search For Course Section',
      class: 'form-control',
      name: 'ajax',
    };

    const timeouts = {};

    const courseSectionFetchResults = (query, course) => {
      if (timeouts[course.id]) {
        clearTimeout(timeouts[course.id]);
      }
      timeouts[course.id] = setTimeout(async () => {
        // course.selectedSection = null;
        const sectionsPromise = store.dispatch('rosterStoreModule/fetchCourseSectionList', {
          search: query,
          populate: 'course,instructor',
          course: course.id,
          sortBy: 'startDate:desc',
        });

        Promise.all([sectionsPromise]).then((values) => {
          course.suggestedSections = [];
          const sections = values[0].data.results;
          sections.forEach((section) => {
            section.name = resolveCourseSectionName(section);
          });
          sections.length && course.suggestedSections.push({ name: 'sections', data: sections });
        });
      }, 250);
    };

    const onCourseSectionSelected = (query, course) => {
      course.selectedSection = query.item;
    };

    pathwayCohortSections.value = props.pathwayData.courses.map((_course) => {
      const course = { ..._course, selectedSection: null, suggestedSections: [] };
      courseSectionFetchResults('', course);
      return course;
    });

    console.log(pathwayCohortSections.value);
    return {
      avatarText,
      busy,

      pathwayCohortCreateData,

      statusOptions,
      visibilityOptions,

      pathwayCohortSections,
      courseSectionInputProps,

      courseSectionFetchResults,
      onCourseSectionSelected,

      resolveCourseSectionName,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
